import LazyOModalAccountChangePassword from "~/organizms/OModal/AccountChangePassword.vue";
import LazyOModalAccountEditContact from "~/organizms/OModal/AccountEditContact.vue";
import LazyOModalBingoHowItWorks from "~/organizms/OModal/Bingo/HowItWorks.vue";
import LazyOModalBingoPlay from "~/organizms/OModal/Bingo/Play.vue";
import LazyOModalBingoWelcome from "~/organizms/OModal/Bingo/Welcome.vue";
import LazyOModalCheckEmail from "~/organizms/OModal/CheckEmail.vue";
import LazyOModalCoinDropsWelcome from "~/organizms/OModal/CoinDrops/Welcome.vue";
import LazyOModalCollectionsGames from "~/organizms/OModal/CollectionsGames.vue";
import LazyOModalCosmicBoxesHowItWorks from "~/organizms/OModal/CosmicBoxes/HowItWorks.vue";
import LazyOModalCosmicBoxesSelection from "~/organizms/OModal/CosmicBoxes/Selection.vue";
import LazyOModalCosmicBoxesWelcome from "~/organizms/OModal/CosmicBoxes/Welcome.vue";
import LazyOModalDailyWheel from "~/organizms/OModal/DailyWheel.vue";
import LazyOModalDailyWheelRules from "~/organizms/OModal/DailyWheelRules.vue";
import LazyOModalEasyProfileCompleted from "~/organizms/OModal/EasyProfileCompleted.vue";
import LazyOModalEmailConfirm from "~/organizms/OModal/EmailConfirm.vue";
import LazyOModalForgotPassword from "~/organizms/OModal/ForgotPassword.vue";
import LazyOModalForgotPasswordSent from "~/organizms/OModal/ForgotPasswordSent.vue";
import LazyOModalGameExclusive from "~/organizms/OModal/Game/Exclusive.vue";
import LazyOModalGame from "~/organizms/OModal/Game/index.vue";
import LazyOModalGameSwitchToSweeps from "~/organizms/OModal/Game/SwitchToSweeps.vue";
import LazyOModalGameUnlockVip from "~/organizms/OModal/Game/UnlockVip.vue";
import LazyOModalGameVipOverview from "~/organizms/OModal/Game/VipOverview.vue";
import LazyOModalInsufficientFunds from "~/organizms/OModal/InsufficientFunds.vue";
import LazyOModalInsufficientWithdrawal from "~/organizms/OModal/InsufficientWithdrawal.vue";
import LazyOModalInviteFriends from "~/organizms/OModal/InviteFriends.vue";
import LazyOModalLeaderboard from "~/organizms/OModal/Leaderboard.vue";
import LazyOModalLogin from "~/organizms/OModal/Login.vue";
import LazyOModalPhoneConfirmation from "~/organizms/OModal/PhoneConfirmation.vue";
import LazyOModalPhoneConfirmationInfo from "~/organizms/OModal/PhoneConfirmationInfo.vue";
import LazyOModalPresetPackage from "~/organizms/OModal/PresetPackage.vue";
import LazyOModalPrizeDropsWin from "~/organizms/OModal/PrizeDropsWin.vue";
import LazyOModalPrizeHub from "~/organizms/OModal/PrizeHub.vue";
import LazyOModalPrizeVaultHowItWorks from "~/organizms/OModal/PrizeVault/HowItWorks.vue";
import LazyOModalPrizeVaultPlay from "~/organizms/OModal/PrizeVault/Play.vue";
import LazyOModalPrizeVaultWelcome from "~/organizms/OModal/PrizeVault/Welcome.vue";
import LazyOModalProfile from "~/organizms/OModal/Profile.vue";
import LazyOModalPromoHowItWorks from "~/organizms/OModal/Promo/HowItWorks.vue";
import LazyOModalPromoInviteFriends from "~/organizms/OModal/Promo/InviteFriends.vue";
import LazyOModalPwaApps from "~/organizms/OModal/Pwa/Apps.vue";
import LazyOModalPwaNotification from "~/organizms/OModal/Pwa/Notification.vue";
import LazyOModalRaceGames from "~/organizms/OModal/RaceGames.vue";
import LazyOModalReferralInfo from "~/organizms/OModal/ReferralInfo.vue";
import LazyOModalRegistrationComplete from "~/organizms/OModal/RegistrationComplete.vue";
import LazyOModalScratchCardsHowItWorks from "~/organizms/OModal/ScratchCards/HowItWorks.vue";
import LazyOModalScratchCardsTicketPlay from "~/organizms/OModal/ScratchCards/TicketPlay.vue";
import LazyOModalScratchCardsWelcome from "~/organizms/OModal/ScratchCards/Welcome.vue";
import LazyOModalScratchCardsWelcomeCamp from "~/organizms/OModal/ScratchCards/WelcomeCamp.vue";
import LazyOModalScratchCardsYouWon from "~/organizms/OModal/ScratchCards/YouWon.vue";
import LazyOModalShowPrizes from "~/organizms/OModal/ShowPrizes.vue";
import LazyOModalSignup from "~/organizms/OModal/Signup.vue";
import LazyOModalSignupExitPopup from "~/organizms/OModal/SignupExitPopup.vue";
import LazyOModalSpookySeasonCongratulations from "~/organizms/OModal/SpookySeason/Congratulations.vue";
import LazyOModalSpookySeasonTower from "~/organizms/OModal/SpookySeason/Tower.vue";
import LazyOModalSpookySeasonWelcome from "~/organizms/OModal/SpookySeason/Welcome.vue";
import LazyOModalSuccessReferral from "~/organizms/OModal/SuccessReferral.vue";
import LazyOModalSuperCoinInfo from "~/organizms/OModal/SuperCoinInfo.vue";
import LazyOModalTermsDialog from "~/organizms/OModal/TermsDialog.vue";
import LazyOModalTournamentGames from "~/organizms/OModal/TournamentGames.vue";
import LazyOModalTournamentHowItWorks from "~/organizms/OModal/TournamentHowItWorks.vue";
import LazyOModalVipClubClaimReward from "~/organizms/OModal/VipClub/ClaimReward.vue";
import LazyOModalVipClubLevelUp from "~/organizms/OModal/VipClub/LevelUp.vue";
import LazyOModalVipClubSaveProgressVip from "~/organizms/OModal/VipClub/SaveProgressVip.vue";
import LazyOModalVipClubStatusRewardDetails from "~/organizms/OModal/VipClub/StatusRewardDetails.vue";
import LazyOModalVipClubStatusUp from "~/organizms/OModal/VipClub/StatusUp.vue";
import LazyOModalVipClubUnlockVip from "~/organizms/OModal/VipClub/UnlockVip.vue";
import LazyOModalVipClubWeeklyReward from "~/organizms/OModal/VipClub/WeeklyReward.vue";
import LazyOModalVipClubWhatIsPoints from "~/organizms/OModal/VipClub/WhatIsPoints.vue";
import LazyOModalWelcome from "~/organizms/OModal/Welcome.vue";
import LazyOModalWheelOfWinsCashDeskWarning from "~/organizms/OModal/WheelOfWins/CashDeskWarning.vue";
import LazyOModalWheelOfWinsHowItWorks from "~/organizms/OModal/WheelOfWins/HowItWorks.vue";
import LazyOModalWheelOfWinsPlayWheel from "~/organizms/OModal/WheelOfWins/PlayWheel.vue";
import LazyOModalWheelOfWinsWelcome from "~/organizms/OModal/WheelOfWins/Welcome.vue";
import LazyOModalWithoutDepositBonus from "~/organizms/OModal/WithoutDepositBonus.vue";

export type Modals = {
	LazyOModalForgotPassword: InstanceType<typeof LazyOModalForgotPassword>["$props"];
	LazyOModalForgotPasswordSent: InstanceType<typeof LazyOModalForgotPasswordSent>["$props"];
	LazyOModalLogin: InstanceType<typeof LazyOModalLogin>["$props"];
	LazyOModalSignup: InstanceType<typeof LazyOModalSignup>["$props"];
	LazyOModalSignupExitPopup: InstanceType<typeof LazyOModalSignupExitPopup>["$props"];
	LazyOModalInviteFriends: InstanceType<typeof LazyOModalInviteFriends>["$props"];
	LazyOModalEmailConfirm: InstanceType<typeof LazyOModalEmailConfirm>["$props"];
	LazyOModalCheckEmail: InstanceType<typeof LazyOModalCheckEmail>["$props"];
	LazyOModalPromoHowItWorks: InstanceType<typeof LazyOModalPromoHowItWorks>["$props"];
	LazyOModalPromoInviteFriends: InstanceType<typeof LazyOModalPromoInviteFriends>["$props"];
	LazyOModalAccountEditContact: InstanceType<typeof LazyOModalAccountEditContact>["$props"];
	LazyOModalAccountChangePassword: InstanceType<typeof LazyOModalAccountChangePassword>["$props"];
	LazyOModalProfile: InstanceType<typeof LazyOModalProfile>["$props"];
	LazyOModalPhoneConfirmation: InstanceType<typeof LazyOModalPhoneConfirmation>["$props"];
	LazyOModalPhoneConfirmationInfo: InstanceType<typeof LazyOModalPhoneConfirmationInfo>["$props"];
	LazyOModalWelcome: InstanceType<typeof LazyOModalWelcome>["$props"];
	LazyOModalDailyWheel: InstanceType<typeof LazyOModalDailyWheel>["$props"];
	LazyOModalDailyWheelRules: InstanceType<typeof LazyOModalDailyWheelRules>["$props"];
	LazyOModalRaceGames: InstanceType<typeof LazyOModalRaceGames>["$props"];
	LazyOModalTournamentHowItWorks: InstanceType<typeof LazyOModalTournamentHowItWorks>["$props"];
	LazyOModalShowPrizes: InstanceType<typeof LazyOModalShowPrizes>["$props"];
	LazyOModalLeaderboard: InstanceType<typeof LazyOModalLeaderboard>["$props"];
	LazyOModalRegistrationComplete: InstanceType<typeof LazyOModalRegistrationComplete>["$props"];
	LazyOModalTermsDialog: InstanceType<typeof LazyOModalTermsDialog>["$props"];
	LazyOModalPresetPackage: InstanceType<typeof LazyOModalPresetPackage>["$props"];
	LazyOModalSpookySeasonCongratulations: InstanceType<typeof LazyOModalSpookySeasonCongratulations>["$props"];
	LazyOModalSpookySeasonTower: InstanceType<typeof LazyOModalSpookySeasonTower>["$props"];
	LazyOModalSpookySeasonWelcome: InstanceType<typeof LazyOModalSpookySeasonWelcome>["$props"];
	LazyOModalSuperCoinInfo: InstanceType<typeof LazyOModalSuperCoinInfo>["$props"];
	LazyOModalWheelOfWinsHowItWorks: InstanceType<typeof LazyOModalWheelOfWinsHowItWorks>["$props"];
	LazyOModalWheelOfWinsWelcome: InstanceType<typeof LazyOModalWheelOfWinsWelcome>["$props"];
	LazyOModalWheelOfWinsPlayWheel: InstanceType<typeof LazyOModalWheelOfWinsPlayWheel>["$props"];
	LazyOModalWheelOfWinsCashDeskWarning: InstanceType<typeof LazyOModalWheelOfWinsCashDeskWarning>["$props"];
	LazyOModalPrizeHub: InstanceType<typeof LazyOModalPrizeHub>["$props"];
	LazyOModalTournamentGames: InstanceType<typeof LazyOModalTournamentGames>["$props"];
	LazyOModalGameUnlockVip: InstanceType<typeof LazyOModalGameUnlockVip>["$props"];
	LazyOModalGame: InstanceType<typeof LazyOModalGame>["$props"];
	LazyOModalInsufficientFunds: InstanceType<typeof LazyOModalInsufficientFunds>["$props"];
	LazyOModalInsufficientWithdrawal: InstanceType<typeof LazyOModalInsufficientWithdrawal>["$props"];
	LazyOModalCollectionsGames: InstanceType<typeof LazyOModalCollectionsGames>["$props"];
	LazyOModalPwaApps: InstanceType<typeof LazyOModalPwaApps>["$props"];
	LazyOModalPwaNotification: InstanceType<typeof LazyOModalPwaNotification>["$props"];
	LazyOModalGameSwitchToSweeps: InstanceType<typeof LazyOModalGameSwitchToSweeps>["$props"];
	LazyOModalPrizeDropsWin: InstanceType<typeof LazyOModalPrizeDropsWin>["$props"];
	LazyOModalCoinDropsWelcome: InstanceType<typeof LazyOModalCoinDropsWelcome>["$props"];
	LazyOModalGameExclusive: InstanceType<typeof LazyOModalGameExclusive>["$props"];
	LazyOModalGameVipOverview: InstanceType<typeof LazyOModalGameVipOverview>["$props"];
	LazyOModalVipClubWhatIsPoints: InstanceType<typeof LazyOModalVipClubWhatIsPoints>["$props"];
	LazyOModalVipClubClaimReward: InstanceType<typeof LazyOModalVipClubClaimReward>["$props"];
	LazyOModalVipClubStatusRewardDetails: InstanceType<typeof LazyOModalVipClubStatusRewardDetails>["$props"];
	LazyOModalVipClubSaveProgressVip: InstanceType<typeof LazyOModalVipClubSaveProgressVip>["$props"];
	LazyOModalVipClubUnlockVip: InstanceType<typeof LazyOModalVipClubUnlockVip>["$props"];
	LazyOModalVipClubWeeklyReward: InstanceType<typeof LazyOModalVipClubWeeklyReward>["$props"];
	LazyOModalVipClubLevelUp: InstanceType<typeof LazyOModalVipClubLevelUp>["$props"];
	LazyOModalVipClubStatusUp: InstanceType<typeof LazyOModalVipClubStatusUp>["$props"];
	LazyOModalWithoutDepositBonus: InstanceType<typeof LazyOModalWithoutDepositBonus>["$props"];
	LazyOModalScratchCardsWelcome: InstanceType<typeof LazyOModalScratchCardsWelcome>["$props"];
	LazyOModalScratchCardsWelcomeCamp: InstanceType<typeof LazyOModalScratchCardsWelcomeCamp>["$props"];
	LazyOModalScratchCardsHowItWorks: InstanceType<typeof LazyOModalScratchCardsHowItWorks>["$props"];
	LazyOModalScratchCardsTicketPlay: InstanceType<typeof LazyOModalScratchCardsTicketPlay>["$props"];
	LazyOModalScratchCardsYouWon: InstanceType<typeof LazyOModalScratchCardsYouWon>["$props"];
	LazyOModalReferralInfo: InstanceType<typeof LazyOModalReferralInfo>["$props"];
	LazyOModalSuccessReferral: InstanceType<typeof LazyOModalSuccessReferral>["$props"];
	LazyOModalEasyProfileCompleted: InstanceType<typeof LazyOModalEasyProfileCompleted>["$props"];
	LazyOModalBingoHowItWorks: InstanceType<typeof LazyOModalBingoHowItWorks>["$props"];
	LazyOModalBingoWelcome: InstanceType<typeof LazyOModalBingoWelcome>["$props"];
	LazyOModalBingoPlay: InstanceType<typeof LazyOModalBingoPlay>["$props"];
	LazyOModalPrizeVaultPlay: InstanceType<typeof LazyOModalPrizeVaultPlay>["$props"];
	LazyOModalPrizeVaultHowItWorks: InstanceType<typeof LazyOModalPrizeVaultHowItWorks>["$props"];
	LazyOModalPrizeVaultWelcome: InstanceType<typeof LazyOModalPrizeVaultWelcome>["$props"];
	LazyOModalCosmicBoxesHowItWorks: InstanceType<typeof LazyOModalCosmicBoxesHowItWorks>["$props"];
	LazyOModalCosmicBoxesSelection: InstanceType<typeof LazyOModalCosmicBoxesSelection>["$props"];
	LazyOModalCosmicBoxesWelcome: InstanceType<typeof LazyOModalCosmicBoxesWelcome>["$props"];
};

const useNlcModals = () => useModals<Modals>();

export default useNlcModals;
