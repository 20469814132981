import type { HomeBanners, BannerPresetPackage, HomeGamesList, Promotion, Game } from "@/types";

const useHomePage = () => {
	const { t } = useT();
	const { select } = useGamesState();
	const { data: appInitData } = useAppInitData();
	const { isSweepStakes } = useSwitchMode();
	const { data: homePageData, refresh, jackpotGames } = useHomeData({ cached: false, server: true });
	const menu = useState("catalog-menu");
	const { isDesktop } = useDevice();
	const { add } = useGamesState();
	const favorites = ref<string>("favorites");
	const isGuest = useIsGuest();

	const fetchDataFavorites = () =>
		useAsyncFetch({
			path: `/rest/page/issues/${favorites.value as "all-games"}/`,
			method: "get",
			options: {
				default: () => ({
					payload: {
						games: Array.from({ length: 25 }).map(() => Infinity),
						menu: menu.value
					}
				}),
				transform(data) {
					return {
						...data,
						payload: {
							...data.payload,
							games: add(data?.payload?.games || [])
						}
					};
				}
			},
			fetchOptions: () => ({
				onResponse: ({ response }) => {
					menu.value = response._data.payload.menu || [];
				}
			})
		});

	const isProfileCompleted = computed(() => {
		const { name, surname, birthDay, profileCompleted } = appInitData?.value || {};
		return profileCompleted || Boolean(name && surname && birthDay);
	});

	const lastGames = computed(() => select(appInitData.value?.lastGames || []));
	const gameOfWeek = computed(() => select(homePageData?.value?.gameOfWeek || [])[0]);

	const seoData = computed(() => homePageData.value?.seo);

	const partitionCb = (columnCount: number) => (acc: HomeGamesList[], item: Game, index: number) => {
		const chunkIndex = Math.floor(index / columnCount);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item as HomeGamesList[0]);
		return acc;
	};

	const jackpotGamesArrayable = computed(() => {
		const list = jackpotGames.value?.reduce(partitionCb(2), [] as HomeGamesList[]);
		return isDesktop ? list : list.slice(0, 3) || [];
	});

	const promotions = computed(() => {
		if (!homePageData.value?.promotions) {
			return Array(4).fill({
				skeleton: true
			});
		}
		return (
			homePageData.value?.promotions?.map((item) =>
				createDecorator(t)(item as Promotion, isSweepStakes.value, isProfileCompleted.value)
			) || []
		);
	});

	const isValidBannerPresetPackage = computed(
		() =>
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.imageMobile2x &&
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.image2x &&
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.imageBanner &&
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.imageBannerMobile
	);

	const banners = computed(() => {
		if (appInitData.value?.bannerPresetPackage && isValidBannerPresetPackage.value) {
			return [
				{
					type: "bannerPresetPackage",
					...appInitData.value.bannerPresetPackage
				},
				...(homePageData.value?.banners || [])
			] as HomeBanners & BannerPresetPackage[];
		}
		return (homePageData.value?.banners || []) as HomeBanners & BannerPresetPackage[];
	});

	const bannerEnabled = computed(() => !!appInitData.value?.isBannerSliderEnabled);

	watch(isGuest, (value) => {
		if (!value) {
			fetchDataFavorites();
		}
	});

	return {
		lastGames,
		promotions,
		gameOfWeek,
		jackpotGamesArrayable,
		seoData,
		homePageData,
		banners,
		bannerEnabled,
		refresh
	};
};
export default useHomePage;
