<script setup lang="ts">
const { modals, close, open } = useNlcModals();
const { data: appInit } = await useAppInitData();
const route = useRoute();
useCookiesSetPromoCode();

const dictionary = new Map([
	["login", "LazyOModalLogin"],
	["register", "LazyOModalSignup"],
	["register1", "LazyOModalSignup"],
	["register2", "LazyOModalSignup"],
	["register3", "LazyOModalSignup"],
	["register4", "LazyOModalSignup"],
	["spookySeason", "LazyOModalSpookySeasonTower"]
]);

onMounted(() => {
	if (!appInit.value?.isGuest && route.query.openModal !== "spookySeason") {
		return;
	}
	const { openModal, errorMessage, invited_by: invitedBy } = route.query;

	if (invitedBy) {
		open("LazyOModalSignup");
	}

	if (typeof openModal !== "string" || !dictionary.has(openModal)) {
		return;
	}
	const modal = dictionary.get(openModal);
	if (!modal) {
		return;
	}

	const error = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
	const errorString = typeof error === "string" ? error : undefined;

	open(modal as Parameters<typeof open>[0], { error: errorString });
});
</script>

<template>
	<div>
		<Teleport to="body">
			<TransitionGroup :name="modals.prevSize === 0 || modals.nextSize === 0 ? 'modal' : 'multiple'" appear>
				<AOverlay
					v-for="[ModalComponent, properties] in modals.items.entries()"
					:id="ModalComponent.replace('LazyO', '')"
					:key="ModalComponent"
					:modifiers="['flex-center']"
					:style="{ background: 'var(--custom-rgb-10)' }"
				>
					<component v-bind="{ onClose: () => close(ModalComponent), ...properties }" :is="ModalComponent" />
				</AOverlay>
			</TransitionGroup>
		</Teleport>
	</div>
</template>

<style scoped lang="scss">
.modal-enter-active,
.modal-leave-active {
	transition: opacity 350ms ease-in-out;
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	z-index: 10001;

	&#ModalTermsDialog {
		z-index: 11000;
	}

	&#ModalRegistrationComplete {
		z-index: 10005;
	}
	&#ModalGame {
		z-index: 2000;
	}
}
.overlay:has(.game-modal, .insufficient-funds-modal) {
	background: transparent !important;
	max-height: calc(100% - 64px);
	margin: gutter(8) 0 0 0;
	z-index: 2000;
	cursor: auto;

	@include media-breakpoint-down(lg) {
		background: var(--gradient-27) !important;
		max-height: 100%;
		margin: 0;
	}
}

.overlay:has(.header-email-confirmation, .insufficient-funds-modal) {
	max-height: calc(100% - 118px);
	margin: gutter(14.75) 0 0 0;

	@include media-breakpoint-down(lg) {
		max-height: 100%;
		margin: 0;
	}
}

@include media-breakpoint-up(xxl) {
	#ModalInsufficientFunds {
		top: auto;
		bottom: calc(50% - 40px);
		left: 50%;
		right: auto;
		margin: 0;
		max-width: 780px;
		width: 100%;
		transform: translate(-50%, 50%);
		overflow: initial;

		&:deep(.insufficient-funds-modal) {
			margin: 0;

			&.with-sidebar {
				margin: 0;
				transform: translateX(-130px);
			}
		}
	}
}
</style>
