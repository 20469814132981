const usePresetPackageInit = () => {
	const { open } = useNlcModals();
	const { data } = useAppInitData();
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const guardFunction = usePopupPresetPackageGuard();
	const { openDefault, openAfterAuth } = usePresetPackageController();
	const { SKIN_CAMP } = useScretchCardData({ immediate: data.value?.scratchCardLottery?.isActive });
	const { depositStreakIsActive, prizeDropsIsActive, scratchCardsIsActive, bingoLotteryIsActive, cosmicBoxesIsActive } =
		useFeaturesIsActive();

	watch(
		() => data.value?.isGuest,
		(value, oldValue) => {
			if (!value && oldValue) {
				setTimeout(() => {
					if (prizeDropsIsActive.value) {
						open("LazyOModalCoinDropsWelcome");
					}
					if (depositStreakIsActive.value) {
						open("LazyOModalWheelOfWinsWelcome");
					}
					if (scratchCardsIsActive.value) {
						SKIN_CAMP ? open("LazyOModalScratchCardsWelcomeCamp") : open("LazyOModalScratchCardsWelcome");
					}
					if (bingoLotteryIsActive.value) {
						open("LazyOModalBingoWelcome");
					}
					if (cosmicBoxesIsActive.value) {
						open("LazyOModalCosmicBoxesWelcome");
					}
				}, 5000);

				openDefault();
			}
		}
	);

	watch(
		() => route.query,
		() => {
			loginGuard({
				success: () => {
					if (route.query.action === "login") {
						openAfterAuth();
					}
				}
			});
		}
	);

	onMounted(() => {
		guardFunction({
			success: () => {
				if (route?.path?.includes("game") || route?.query?.game || route?.hash?.includes("cash")) {
					return;
				}

				if (route?.query?.redirectUrl === "bannerPresetPackage" || route.query.action === "login") {
					openAfterAuth();
				}
			}
		});
	});
};

export default usePresetPackageInit;
