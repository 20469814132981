<script lang="ts" setup>
const props = defineProps<{
	initialDuration: number;
	title: string;
	text: string;
	btnText: string;
	timerText: string;
}>();
const durationLeft = ref(props.initialDuration);

let intervalId: ReturnType<typeof setInterval> | null = null;

const clearToastInterval = () => {
	if (intervalId) {
		clearInterval(intervalId);
		intervalId = null;
	}
};

onMounted(() => {
	intervalId = setInterval(() => {
		if (durationLeft.value > 0) {
			durationLeft.value -= 1;
			return;
		}

		clearToastInterval();
	}, 1000);
});

onBeforeUnmount(() => {
	clearToastInterval();
});
</script>

<template>
	<div>
		<div class="title">
			<span>{{ title }}</span>
			<span class="timer-text color-primary-50">{{ durationLeft }}&nbsp;{{ timerText }}</span>
		</div>
		<div class="subtitle">{{ text }}</div>
		<button class="btn">{{ btnText }}</button>
	</div>
</template>

<style scoped lang="scss">
.title {
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: var(--neutral-100);
}

.subtitle {
	margin: 4px 0 12px;
	font-weight: 400;
	line-height: 16px;
	color: var(--neutral-70);
}

.btn {
	padding: 4px;
	width: 125px;
	text-align: center;
	border-radius: 8px;
	font-weight: 700;
	line-height: 22px;
	background: var(--primary-50);
	box-shadow: 0 1px 2px 0 rgba(var(--primary-15), 0.05);
	border: 1px solid var(--primary-50);
	color: var(--neutral-100);
	outline: none;
	text-transform: uppercase;
}
</style>
<style lang="scss">
#prize-receive-timer {
	.Toastify {
		&__toast {
			background: var(--neutral-15);

			@include media-breakpoint-down(lg) {
				margin-top: 16px;
			}
		}
		&__toast-body {
			white-space: normal;
			font-size: 12px;
		}
		&__toast-icon {
			width: 40px;
		}

		&__close-button {
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 1;
			height: auto;
			opacity: 1;
			svg {
				position: relative;
				height: 16px;
				width: 16px;
			}
		}
	}
}
</style>
