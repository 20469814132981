
import * as ipxRuntime$mEOUMuRqxf from '/app/node_modules/.pnpm/@nuxt+image-edge@1.3.0-28493895.369b747_ioredis@5.4.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1400,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$mEOUMuRqxf, defaults: {} }
}
        