import type { v1, ExtractFromAPI } from "@netgame/openapi";
import { defu } from "defu";

import useAppInitData from "./useAppInitData";

type GetGameFiltersResponse = ExtractFromAPI<v1.paths, "/rest/get-game-filters/", "get">;
type IssuesAllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
type GetGameFiltersResponseData = NonNullable<GetGameFiltersResponse["data"]>;
type IssuesAllGamesResponsePayload = NonNullable<IssuesAllGamesResponse["payload"]>;
type Games = NonNullable<GetGameFiltersResponseData["games"]>;
type Game = NonNullable<Games[number]>;
type IssueGame = NonNullable<IssuesAllGamesResponsePayload["games"]>[number];
type GameInfo = {
	title: string;
	logo: string;
	rowsPerSlide: number;
};
type GameObject = {
	title?: string;
	logo?: string;
	rowsPerSlide?: number;
};

const ROWS_MAP: Record<string, number> = {
	popularGames: 3,
	topGames: 1,
	bonusCombo: 1
};

const useGamesState = () => {
	const all = useState<Record<number, Game>>("games", () => Object.create(null));
	const { favoritesSet } = useAppInitData();

	const add = (games: Array<Game & IssueGame>) =>
		games.map((game) => {
			if (!all.value) {
				all.value = Object.create(null);
			}
			all.value![game.id] = defu(game, all.value![game.id] || Object.create(null));
			return game.id;
		});

	const select = (ids: Array<number>) =>
		ids.reduce((acc: Array<Game>, id) => {
			if (!all.value![id]) {
				return acc;
			}
			return [
				...acc,
				{
					...all.value![id],
					isFavorite: favoritesSet.value.has(id),
					logo: all.value![id].img || all.value![id].logo
				}
			];
		}, []);

	const getInfo = (obj: GameObject = {}, key: string = ""): GameInfo => {
		const rowsPerSlide = ROWS_MAP[key] ?? 2;
		return {
			title: obj.title ?? "",
			logo: obj.logo ?? "",
			rowsPerSlide
		};
	};

	return {
		all,
		add,
		select,
		getInfo
	};
};

export default useGamesState;
