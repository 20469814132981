import useAppInitData from "./useAppInitData";

const useFeaturesIsActive = () => {
	const { data: appInitData } = useAppInitData();

	const seasonTowerIsActive = computed(() => appInitData.value?.season?.isEnabled);

	const depositStreakIsActive = computed(
		() =>
			appInitData.value?.depositStreak?.isActive && appInitData.value?.depositStreak?.skin === "neutral_wheel_of_wins"
	);
	const prizeVaultIsActive = computed(
		() => appInitData.value?.depositStreak?.isActive && appInitData.value?.depositStreak?.skin === "prize_vault"
	);
	const bingoLotteryIsActive = computed(() => appInitData.value?.bingo?.isActive);

	const scratchCardsIsActive = computed(() => appInitData.value?.scratchCardLottery?.isActive);

	const cosmicBoxesIsActive = computed(() => appInitData.value?.magicBox?.isActive);

	const magicBoxesIsActive = computed(() => appInitData.value?.magicBox?.isActive);

	const prizeDropsIsActive = computed(() => appInitData.value?.prizeDrops?.isActive);

	const rankLeagueIsActive = computed(() => appInitData.value?.rankLeague?.enabled);

	const affiliatePartnerIsActive = computed(() => appInitData.value?.isAffiliate);

	const topEmailNotificationIsActive = computed(
		() => appInitData.value?.primaryContact === "email" && !appInitData.value.emailConfirmed
	);

	return {
		seasonTowerIsActive,
		depositStreakIsActive,
		prizeVaultIsActive,
		bingoLotteryIsActive,
		scratchCardsIsActive,
		magicBoxesIsActive,
		prizeDropsIsActive,
		rankLeagueIsActive,
		cosmicBoxesIsActive,
		affiliatePartnerIsActive,
		topEmailNotificationIsActive
	};
};

export default useFeaturesIsActive;
